import { ScrollView } from "react-native";
import Markdown from "react-native-markdown-display";
import { useAuthContext } from "../AuthContext";
import { useEffect } from "react";

const doc = `
# Terms of Service ("Terms")

Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the Dialog web or mobile application ("App") operated by Dialog Technology Inc. ("us", "we", or "our").

Your access to and use of the App is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the App.

By accessing or using the App, you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the App.

## Accounts:

When you create an account with us, you must provide accurate and complete information. It is your responsibility to maintain the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account or password. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.

## Content:

Our App allows users to engage in one-on-one conversations with others based on specific opinions, experiences, or identities. Users are solely responsible for the content they publish or share on the App. By using our App, you agree not to post, transmit, or share any content that is unlawful, defamatory, obscene, harmful, or otherwise objectionable.

## User Conduct:

You agree to use the App only for lawful purposes and in a manner that does not infringe upon the rights of others. You further agree not to interfere with or disrupt the operation of the App or the networks connected to the App.

## Intellectual Property:

The App and its original content, features, and functionality are owned by Dialog Technology Inc. and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.

## Termination:

We may terminate or suspend access to our App immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.

## Changes:

We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.

## Contact Us:

If you have any questions about these Terms, please contact us at:

support@dialogapp.co

This document was last updated on March 14, 2024.
`;

export const TermsOfServiceScreen: React.FC = () => {
  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      <Markdown style={{ body: { padding: 20 } }}>{doc}</Markdown>
    </ScrollView>
  );
};
