import { View, Text, TextInput } from "react-native";
import styles from "../styles";

export const SunsetScreen: React.FC = () => {
  return (
    <View style={styles.sunsetContainer}>
      <Text style={{ fontSize: 26, textAlign: "center", marginTop: 20 }}>Dialog is no longer available. Thank you for your support!</Text>
      <Text style={{ fontSize: 20, textAlign: "center", marginTop: 20 }}>Please contact support@dialogapp.co if you have any questions.</Text>
    </View>
  );
};
