import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import type React from "react";
import { Feather } from "@expo/vector-icons";
import { type NavigatorScreenParams } from "@react-navigation/native";
import { MenuStack, type MenuStackParamList } from "../menu/MenuStack";
import { SunsetScreen } from "../sunset/SunsetScreen";

export type BottomTabParamList = {
  Sunset: undefined;
  More: NavigatorScreenParams<MenuStackParamList>;
};

const Tab = createBottomTabNavigator<BottomTabParamList>();

export const BottomTabNavigator: React.FC = () => {
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ color, size }) => {
          let iconName:
            | "home"
            | "menu"
            | undefined;

          if (route.name === "Sunset") {
            iconName = "home";
          } else if (route.name === "More") {
            iconName = "menu";
          }

          return <Feather name={iconName} size={size} color={color} />;
        },
        // tabBarShowLabel: route.name === "Menu",
      })}
    >
      <Tab.Screen
        name="Sunset"
        component={SunsetScreen}
        options={{
          headerShown: false,
          tabBarLabel: "Home",
          title: "Home",
        }}
      />
      <Tab.Screen
        name="More"
        component={MenuStack}
        listeners={({ navigation }) => ({
          tabPress: (event) => {
            event.preventDefault();
            navigation.navigate("More", {
              screen: "MenuScreen",
            });
          },
        })}
        options={{
          headerShown: false,
        }}
      />
    </Tab.Navigator>
  );
};
