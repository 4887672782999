import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { MenuScreen } from "./MenuScreen";
import { PrivacyPolicyScreen } from "./PrivacyPolicyScreen";
import { TermsOfServiceScreen } from "./TermsOfServiceScreen";
import { NotFoundScreen } from "./NotFoundScreen";

export type MenuStackParamList = {
  MenuScreen: undefined;
  PrivacyPolicyScreen: undefined;
  TermsOfServiceScreen: undefined;
  NotFoundScreen: undefined;
};

const Stack = createNativeStackNavigator<MenuStackParamList>();

export const MenuStack: React.FC = () => {
  return (
    <Stack.Navigator initialRouteName="MenuScreen">
      <Stack.Screen
        name="MenuScreen"
        component={MenuScreen}
        options={{
          title: "Menu",
          headerShown: false,
          headerBackVisible: false,
        }}
      />
      <Stack.Screen
        name="PrivacyPolicyScreen"
        component={PrivacyPolicyScreen}
        options={{
          title: "Dialog Privacy Policy",
          headerShown: true,
          headerBackVisible: true,
        }}
      />
      <Stack.Screen
        name="TermsOfServiceScreen"
        component={TermsOfServiceScreen}
        options={{
          title: "Dialog Terms of Service",
          headerShown: true,
          headerBackVisible: true,
        }}
      />
      <Stack.Screen
        name="NotFoundScreen"
        component={NotFoundScreen}
        options={{
          title: "Page Not Found",
          headerShown: true,
          headerBackVisible: true,
        }}
      />
    </Stack.Navigator>
  );
};
