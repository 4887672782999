import { View } from "react-native";
import { Feather } from "@expo/vector-icons";
import { MenuLink } from "./MenuLink";

export const MenuScreen: React.FC = () => {

  return (
    <View style={{ flex: 1, margin: 20 }}>
      <MenuLink
        icon={<Feather name="shield" size={30} color="blue" />}
        linkText="Privacy Policy"
        linkTo="/privacy-policy"
      />
      <MenuLink
        icon={<Feather name="check-square" size={30} color="blue" />}
        linkText="Terms of Service"
        linkTo="/terms-of-service"
      />
    </View>
  );
};
