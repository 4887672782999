import { patchFlatListProps } from "react-native-web-refresh-control";
import { View } from "react-native";
import { MenuProvider } from "react-native-popup-menu";
import styles from "./styles";
import { AppHeader } from "./AppHeader";
import { MainNavigation } from "./navigation/MainNavigation";
import ReactGA from "react-ga4";
import { useEffect } from "react";


const GOOGLE_ANALYTICS_ID = process.env.GOOGLE_ANALYTICS_ID ?? "G-GWVZ28JJ3Q";


// Enables pull to refresh on mobile web
patchFlatListProps();

const MainApp: React.FC = () => {
  return (
    <MenuProvider>
      <View style={styles.appContainer}>
        <AppHeader />
        <MainNavigation />
      </View>
    </MenuProvider>
  );
};

export const DialogApp: React.FC = () => {
  useEffect(() => {
    if (GOOGLE_ANALYTICS_ID) {
      ReactGA.initialize(GOOGLE_ANALYTICS_ID);
    }
  }, []);

  return (
    <MainApp />
  );
};
